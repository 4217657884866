export const environment = {
    production: false,
    apiUrl: '//core.reservasinteligentes.com.br/api',
    key:'f9ba502f-b73f-4569-9f1e-68920544e59e',
    bsc_params: {
        chainId: '0x61',
        chainName: 'Testnet Binance Smart Chain',
        nativeCurrency: {
          name: 'Binance Coin',
          symbol: 'BNB',
          decimals: 18
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
        apiUrl: "https://api-testnet.bscscan.com"
      },
    contractAddress:'0xa67e96e4ee5678a05a21e32278abd740593cdd9f'
};
