import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GalleryItem, GalleryModule } from 'ng-gallery';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { environment } from '../../environments/environment';
import { RaiService } from '../services/rai.service';



@Component({
  selector: 'app-parceira',
  standalone: true,
  imports: [GalleryModule,CommonModule, CarouselModule],
  templateUrl: './parceira.component.html',
  styleUrl: './parceira.component.scss'
})
export class ParceiraComponent implements OnInit {

  //@ViewChild(GalleryComponent) gallery!: GalleryComponent;
  items: GalleryItem[] = [];
  imagens:any[] | undefined = undefined;
  idApoiador: any | undefined = undefined;
  apoiador: any | undefined = undefined;


  constructor(private route: ActivatedRoute,
    private raiService: RaiService
  ) {

  }

  ngOnInit() {

    this.route.paramMap.subscribe((params) => {
      this.idApoiador = params.get("id");
      if (this.idApoiador) {
        this.consultar();
      }
    });

  }

  consultar() {

    this.raiService.findByAopiador(this.idApoiador).subscribe(data => {
      this.apoiador = data;
      this.apoiador.apoio._idFileLogo = this.getUrlImagem(this.apoiador.apoio._idFileLogo);
      this.apoiador.nfts.forEach((nft: { imagens: any[]; nft:any }) => {
        nft.nft._idImgNFT = this.getUrlImagem(nft.nft._idImgNFT)
        nft.imagens = nft.imagens.map((imagem: { file: string; thumb: string;}) => {
          return {
            ...imagem,
            file: this.getUrlImagem(imagem.file),
            thumb: this.getUrlImagem(imagem.thumb)  
          };
        });
      });
      setTimeout(() => {
        if( this.apoiador?.nfts?.length > 0 ){
          const images: [] = this.apoiador.nfts[0].imagens.map((img: { file: any; thumb: string; }) => ({ "src": img.file, "thumb": img.thumb + '/thumb' }));
          this.imagens = images;
        }
      }, 1000);

    });

  }

  getUrlImagem(id: any){
    return `${window.location.protocol}${environment.apiUrl}/user/cars/documento/arquivo?carid=${id}`;
  }

  carregarImagens(propriedade:any, event: Event) {
    const images: [] = propriedade.imagens.map((img: { file: any; thumb: string; }) => ({ "src": img.file, "thumb": img.thumb + '/thumb' }));
    this.imagens = images;
    event?.preventDefault();
  }

}
