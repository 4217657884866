<nav class="navbar navbar-expand-lg">
  <div class="container">
    <div class="row justify-content-center w-100">
      <div class="col-12" style="text-align: center;">
        <a class="navbar-brand" href="/">
          <img class="logo-cadastro" src="Logo_HR.png" alt="">
        </a>
      </div>
    </div>
  </div>
</nav>
<section id="features" class="features-section container">
  <div class="row d-flex justify-content-center">
    <div class="col-md-6 mb-4">
      <div class="card card-round">
        <img [src]="apoiador?.apoio?._idFileLogo" alt="Imagem 1" style="padding: 20px;">
      </div>
    </div>

    <div class="col-md-6 mb-4">
      <div class="feature-card h-100">
        <div class="w-100 text-center">
          <i class="fa-solid fa-building-wheat"></i>
          <h5>Dados do Parceiro</h5>
        </div>
        <ul>
          <li><b>Nome:</b> {{apoiador?.apoio?.nomeEmpresa}}</li>
          <li><b>Telefone:</b> {{apoiador?.apoio?.foneContato}}</li>
          <li><b>Email:</b> {{apoiador?.apoio?.email}}</li>
        </ul>
        <section class="cta-section" *ngIf="apoiador.apoio?.site">
          <div class="container">
            <a  *ngIf="apoiador.apoio?.site" [href]="apoiador.apoio?.site" target="_blank" class="btn btn-custom">Visitar Site do Parceiro</a>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div class="row text-center">
    <div class="col-md-6 mb-4">
      <div class="feature-card h-100">
        <i class="fa-solid fa-wallet"></i>
        <h5>Relação de RAIs do parceiro</h5>
        <div class="row d-flex justify-content-center" *ngIf="apoiador?.nfts">
          <div class="col-md-6" *ngFor="let prop of apoiador?.nfts" style=" padding-top: 20px;">
            <div class="card" style="width: 18rem;">
              <img [src]="prop.nft?._idImgNFT" class="card-img-top" alt="Imagem de exemplo">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-md-8 margin-auto">
                    <h5><b>Token ID: </b>{{prop.nft?.idToken}}</h5>
                  </div>
                  <div class="col-md-4" style="margin: auto;">
                    <a href="#" (click)="carregarImagens(prop, $event)" class="icon-link" title="Abrir imagens"><i
                        class="fas fa-image"></i></a>
                    <a [href]="'https://dapp.reservasinteligentes.com.br/mapa/'+prop.carid" target="_blank" title="abrir mapa"
                      class="icon-link"><i class="fa-solid fa-map-location-dot"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 mb-4">
      <div class="feature-card h-100">
        <i class="fa-solid fa-seedling"></i>
        <h5>Relação de imagens e fotos relacionada as Rais do parceiro</h5>
        <!-- <gallery id="basic-test" imageSize="cover" fluid [items]="items"></gallery> -->
          <carousel [isAnimated]="true" *ngIf="imagens">
            <slide *ngFor="let item of imagens">
              <img [src]="item.src" alt="first slide" style="display: block; width: 100%;">
            </slide>
          </carousel>
      </div>
    </div>
  </div>
</section>

<!-- Rodapé -->
<footer class="text-center py-4">
  <p>&copy; 2024 Parceiro Inteligente. Todos os direitos reservados.</p>
</footer>

<!-- <pre>{{apoiador | json}}</pre> -->