import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RaiService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  listarTudo() {
    return this.http.get<any>(`${this.getUrlBase()}home/pesquisar`);
  }

  listarUfs() {
    return this.http.get<any>(`${this.getUrlBase()}home/uf`);
  }

  pesquisar(parametro: any) {
    let params = new HttpParams().set('search', parametro);
    return this.http.get<any>(`${this.getUrlBase()}home/pesquisar`, {
      params,
    });
  }

  getGeoJson(codigoCar: string) {
    return this.http.get<any>(this.getGeoJsonUrl(codigoCar));
  }

  getTokenid(codigoCar: string) {
    return this.http.get<any>(`${this.getUrlBase()}home/tokenid/${codigoCar}`);
  }

  getGeoJsonUrl(codigoCar: string) {
    return this.getUrlBase() + `home/geojson/${codigoCar}`;
  }

  listarArquivos(id: any, categoria: string) {
    const options = categoria ? { params: new HttpParams().set('categoria', categoria) } : {};
    return this.http.get<any>(`${this.getUrlBase()}user/cars/${id}/arquivos`, options);
  }

  findByAopiador(idApoiador: string) {
    return this.http.get<any>(this.getUrlBase() + `nft/metadado/apoiador/${idApoiador}`);
  }
}
